/* src/Feed.css */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  background-color: black;
  color: white;
  font-family: sans-serif;
}

/* --- Keyframes --- */
@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
@keyframes slideInFromTop {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.feed-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: black;
}

.feed-logo {
  position: absolute;
  top: 20px; /* Adjusted top position */
  left: 20px; /* Fixed left position */
  transform: translateX(0); /* Removed translateX */
  z-index: 10;
  max-height: 50px;
  transition: top 0.5s ease-in-out, left 0.5s ease-in-out, transform 0.5s ease-in-out;
}

/* --- Top Right Icons & Profile --- */
.top-right-icons {
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 18px;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s; /* Delay visibility change */
}

.top-right-icons a {
  color: #ff7f00;
  font-size: 1.2em;
  transition: color 0.2s ease, transform 0.2s ease;
}

.top-right-icons a:hover {
  color: #e67300;
  transform: scale(1.1);
}

/* --- Profile Button & Dropdown Header Styles (Reused from Header.css) --- */
.profile-container-header {
  position: relative; /* For dropdown positioning */
  display: flex;
  align-items: center;
}

.profile-button-header {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center; /* Vertically center icon/image */
  justify-content: center; /* Horizontally center content */
}

.desktop-profile-pic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  display: block; /* Ensure no extra space */
}

.profile-initials-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ff7f00; /* Example background color */
  color: white;
  font-weight: bold;
  font-size: 1em;
}

.user-dropdown-header {
  position: absolute;
  top: calc(100% + 10px); /* Position below the button */
  right: 0;
  background-color: #1a1a1a;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  width: 120px; /* Adjust width as needed */
  overflow: hidden; /* Prevent border-radius overflow */
}

.user-dropdown-header .dropdown-item {
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: white;
  font-size: 0.9em;
}

.user-dropdown-header .dropdown-item:hover {
  background-color: #333;
}

.user-dropdown-header .signout-dropdown-item {
  border-top: 1px solid #444; /* Separator for sign-out */
}

.signin-button-custom {
  background-color: #ff7f00;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s ease;
}

.signin-button-custom:hover {
  background-color: #e67300;
}


/* --- Content Wrapper --- */
.feed-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feed-content-wrapper.animate-slide-up {
    animation: slideInFromBottom 0.5s ease-out forwards;
}
.feed-content-wrapper.animate-slide-down {
    animation: slideInFromTop 0.5s ease-out forwards;
}

.feed-thumbnail,
.feed-video,
.feed-iframe,
.feed-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border: none;
}
.feed-placeholder {
    background-color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #555;
}

/* --- Info Box --- */
.feed-info {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 25px 15px 25px;
  border-radius: 8px;
  max-width: 80%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, visibility 0s linear 0.5s; /* Delay visibility change */
  min-height: 110px;
  box-sizing: border-box;
}

.feed-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 8px;
  color: #ff7f00;
}

.feed-description {
  font-size: 0.9em;
  margin-bottom: 10px;
  color: #ccc;
  max-height: 60px;
  overflow-y: auto;
}

.feed-username {
  font-size: 0.8em;
  color: #aaa;
  margin-bottom: 15px;
}
.feed-username a { color: #bbb; text-decoration: none; }
.feed-username a:hover { text-decoration: underline; }

/* --- Action Text (Request Early Access) --- */
.action-text-request {
  color: #ff7f00; /* Ensure orange color */
  background: none;
  border: none;
  padding: 5px 0;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
  display: inline-block;
  margin-top: auto;
}

.action-text-request:hover {
  color: #e67300;
}

/* --- Play Icon Button (Rounded Orange) --- */
.action-icon-play {
    position: absolute;
    bottom: 12px; /* Adjusted position slightly */
    right: 15px; /* Adjusted position slightly */
    background-color: #ff7f00; /* Orange background */
    color: white; /* White icon color for contrast */
    border: none;
    border-radius: 50%; /* Make it round */
    width: 40px; /* Fixed width */
    height: 40px; /* Fixed height */
    display: flex; /* Center icon inside */
    justify-content: center;
    align-items: center;
    font-size: 1em; /* Adjust icon size relative to button size */
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    padding: 0; /* Remove padding if fixed size */
}

.action-icon-play svg { /* Target the FontAwesome icon specifically */
    display: block; /* Ensure it behaves predictably */
}

.action-icon-play:hover {
    background-color: #e67300; /* Darker orange on hover */
    transform: scale(1.1);
}

/* --- Navigation Arrows (Smaller) --- */
.feed-nav-arrow {
  position: absolute;
  right: 15px; /* Adjusted position */
  z-index: 10;
  cursor: pointer;
  font-size: 1.4em; /* Reduced size by ~30% from 2em */
  color: rgba(255, 255, 255, 0.6); /* Slightly more subtle */
  background-color: rgba(0, 0, 0, 0.4); /* Slightly more subtle */
  padding: 4px; /* Adjusted padding */
  border-radius: 50%;
  user-select: none;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, color 0.2s ease, background-color 0.2s ease, visibility 0s linear 0.5s; /* Delay visibility change */
}
.feed-nav-arrow:hover {
   color: rgba(255, 255, 255, 1);
   background-color: rgba(0, 0, 0, 0.7);
}
.arrow-up { top: 40%; transform: translateY(-50%); }
.arrow-down { bottom: 40%; transform: translateY(50%); }
.feed-container[data-single-item="true"] .feed-nav-arrow { display: none; }


/* --- Share Button (Smaller) --- */
.feed-share-button {
  position: absolute;
  bottom: 25px; /* Adjusted position */
  right: 15px; /* Adjusted position */
  z-index: 10;
  cursor: pointer;
  font-size: 1.2em; /* Reduced size */
  color: rgba(255, 255, 255, 0.6); /* Slightly more subtle */
  background-color: rgba(0, 0, 0, 0.4); /* Slightly more subtle */
  padding: 8px; /* Adjusted padding */
  border-radius: 50%;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, color 0.2s ease, background-color 0.2s ease, visibility 0s linear 0.5s; /* Delay visibility change */
}
.feed-share-button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.7);
}
.feed-share-button::before { content: '🔗'; display: block; }


/* --- Leave Button --- */
.feed-leave-button {
  position: absolute;
  top: 20px; /* Aligned with logo */
  right: 20px;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 1px solid #ff7f00;
  padding: 8px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s; /* Delay visibility change */
}

/* --- Custom Notification --- */
.copy-notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    background-color: black;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid white;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    font-size: 0.9em;
}

.copy-notification.visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
}

/* --- Playing State Adjustments --- */
.feed-container.is-playing .feed-logo {
  /* Logo position is now fixed, these rules are removed */
}

/* Hide elements instantly on visibility, fade out on opacity */
.feed-container.is-playing .feed-info,
.feed-container.is-playing .feed-nav-arrow,
.feed-container.is-playing .feed-share-button,
.feed-container.is-playing .top-right-icons {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, visibility 0s linear 0.5s; /* Hide visibility after opacity transition */
}

/* Optional: Add transforms for hiding animation */
.feed-container.is-playing .feed-info {
  transform: translateX(-50%) translateY(30px);
}
.feed-container.is-playing .feed-nav-arrow {
    transform: translateY(30px);
}
.feed-container.is-playing .feed-share-button {
    transform: translateX(30px) translateY(30px); /* Move slightly right/down */
}

/* Make leave button appear */
.feed-container.is-playing .feed-leave-button {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0s linear 0s; /* Make visible instantly when opacity starts */
}


/* Loading/Error states */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure it takes full height to center vertically */
  color: #ff7f00; /* Text color if needed */
}

.orange-spinner {
  border: 3px solid rgba(255, 127, 0, 0.3); /* Light orange border */
  border-top: 3px solid #ff7f00; /* Solid orange border on top */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: rotate 1s linear infinite;
}


.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-card {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
}

.error-title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ff7f00;
}

.error-description {
  font-size: 1em;
  color: #ccc;
}


.loading-overlay {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: #ff7f00;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8em;
    z-index: 20;
}

/* Basic Mobile adjustments */
@media (max-width: 768px) {
  .feed-info {
    bottom: 20px;
    max-width: 90%;
    padding: 15px 20px 10px 20px;
    min-height: 100px;
  }
  .feed-title { font-size: 1.2em; }
  .feed-description { font-size: 0.8em; max-height: 45px; }
  .action-text-request { font-size: 0.9em; }
  .action-icon-play {
      width: 36px;
      height: 36px;
      font-size: 0.9em;
      bottom: 10px;
      right: 12px;
  }

  .feed-nav-arrow {
      font-size: 1.8em; /* Further reduced */
      right: 10px;
      padding: 3px;
  }
  .feed-share-button {
      font-size: 1.1em; /* Further reduced */
      padding: 7px;
      right: 10px;
      bottom: 20px; /* Adjusted */
  }

  .feed-leave-button { top: 10px; right: 10px; padding: 6px 12px; font-size: 0.8em; } /* Adjusted top */
  .top-right-icons { right: 15px; top: 22px; gap: 15px; }
  .top-right-icons a { font-size: 1.1em; }

  .feed-container.is-playing .feed-logo { top: 10px; left: 15px; max-height: 40px;}

  .error-card {
      padding: 20px;
  }
  .error-title {
      font-size: 1.5em;
      margin-bottom: 10px;
  }
  .error-description {
      font-size: 0.9em;
  }
}

/* src/Feed.css - Additions for Auth Modal */

/* Auth Modal Overlay */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

/* Auth Modal Content */
.auth-modal-content {
  background-color: #1a1a1a; /* Dark background for content area */
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  color: white;
}

.auth-modal-title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff7f00; /* Orange title */
}

.auth-modal-checklist {
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
}

.auth-modal-checklist li {
  margin-bottom: 10px;
  font-size: 1em;
  color: #ccc;
  text-align: left; /* Align text to the left for readability */
}
.auth-modal-checklist li::before {
  content: '✓ '; /* Checkmark symbol */
  color: #ff7f00; /* Orange checkmark */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}


/* Auth Modal Buttons Container */
.auth-modal-buttons {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 20px; /* Space between buttons */
}

/* Auth Modal Button Style (Shared) */
.auth-modal-button {
  background-color: #ff7f00; /* Orange background */
  color: white;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s ease;
}

.auth-modal-button:hover {
  background-color: #e67300; /* Darker orange on hover */
}

/* Specific button styles if needed (e.g., for Login/Signup differentiation) */
.auth-modal-login-button {
  /* Add specific styles for login button if needed */
}

.auth-modal-signup-button {
  /* Add specific styles for signup button if needed */
}

/* Mobile Adjustments for Auth Modal */
@media (max-width: 768px) {
  .auth-modal-content {
      padding: 20px;
      max-width: 95%;
  }
  .auth-modal-title {
      font-size: 1.5em;
      margin-bottom: 15px;
  }
  .auth-modal-checklist li {
      font-size: 0.9em;
      margin-bottom: 8px;
  }
  .auth-modal-buttons {
      gap: 15px;
      flex-direction: column; /* Stack buttons on mobile */
      align-items: stretch; /* Make buttons full width */
  }
  .auth-modal-button {
      padding: 12px 20px;
      font-size: 1em;
      width: 100%; /* Full width buttons on mobile */
      box-sizing: border-box; /* Include padding in width */
  }
  .auth-modal-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    color: red; /* Light grey color */
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.2s ease;
    padding: 3px; /* Add some padding for better touch area */
}

.auth-modal-close-button:hover {
    color: white; /* White on hover */
}
}